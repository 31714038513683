<template>
  <v-card class="mt-10">
    <v-card-title>שליח חדש</v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="form">
        <v-row class="basic mt-2">
          <v-col cols="6" md="4">
            <v-text-field
              background-color="white"
              outlined
              dense
              :rules="[(v) => !!v || 'חובה']"
              v-model="delivery.name"
            >
              <template slot="label">
                <strong class="red--text">*</strong>
                שם שליח
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="6" md="4">
            <v-text-field
              background-color="white"
              outlined
              dense
              @keydown="phone_exist = false"
              v-model="delivery.phone"
              type="number"
              :rules="[
                (v) => !!v || 'חובה',
                !phone_exist || 'כבר קיים',
              ]"
            >
              <template slot="label">
                <strong class="red--text">*</strong>
                מספר טלפון
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="6" md="4">
            <v-text-field
              background-color="white"
              outlined
              dense
              v-model="delivery.email"
              label="מייל"
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="4">
            <v-file-input
              background-color="white"
              outlined
              dense
              v-model="delivery.photo"
              @click="photo_err = false"
              :rules="[()=> !photo_err || 'סוג התמונה לא מתאים (jpg, jpeg, png)']"
              prepend-icon=""
              prepend-inner-icon="image"
              label="תמונה"
            ></v-file-input>
          </v-col>
          <!-- <v-col cols="6" md="4" v-if="!delivery.is_manager">
            <v-select
              background-color="white"
              outlined
              dense
              item-text="name"
              item-value="id"
              :items="deliveries"
              v-model="delivery.manager_id"
              :rules="[(v) => !!v || 'חובה']"
            >
              <template slot="label">
                <strong class="red--text">*</strong>
                المدير
              </template>
            </v-select>
          </v-col> -->

          <v-col cols="6" md="4">
            <v-text-field
              background-color="white"
              readonly
              outlined
              dense
              label="סטטוס"
              :value="delivery.status ? 'פעיל' : 'לא פעיל'"
            >
              <template slot="append">
                <v-switch
                  v-model="delivery.status"
                  class="mt-0"
                  hide-details
                ></v-switch>
              </template>
            </v-text-field>
          </v-col>

          <!-- <v-col cols="6" md="4">
            <v-checkbox
              v-model="delivery.is_manager"
              label="المدير"
            ></v-checkbox>
          </v-col> -->
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="justify-end px-4">
      <div class="d-flex">
        <v-btn
          @click="close_form()"
          x-large
          depressed
          height="40"
          color="black--text"
        >
          <v-icon>arrow_forward</v-icon>
        </v-btn>
        <v-btn
          :disabled="save_loader"
          :loading="save_loader"
          x-large
          depressed
          height="40"
          @click="save()"
          color="black white--text ms-3"
          >שמירה</v-btn
        >
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "Form",
  props: ["places", "groups"],
  data() {
    return {
      photo_err:false,
      phone_exist: false,
      id_number_exist: false,
      save_loader: false,
      deliveries: [],
      delivery: {
        name: "",
        phone: null,
        email: "",
        status: true,
        manager_id: null,
        is_manager: true,
      },
    };
  },
  computed: {},
  methods: {
    close_form() {
      if (this.save_loader) {
        this.$emit("update_table");
        this.save_loader = false;
      }
      this.$emit("close_form");
    },
    save() {
      if (this.$refs.form.validate() && !this.save_loader) {
        this.save_loader = true;
        const formData = new FormData();
        formData.append('name', this.delivery.name);
        formData.append('phone', this.delivery.phone);
        formData.append('photo', this.delivery.photo || '');
        formData.append('email', this.delivery.email);
        formData.append('status', this.delivery.status);
        formData.append('manager_id', this.delivery.manager_id || '');
        formData.append('is_manager', this.delivery.is_manager);

        this.$store
          .dispatch("public__request", {
            config: {
              url: `delivery`,
              method: "post",
            },
            data: formData,
          })
          .then((res) => {
            this.close_form();
          })
          .catch((err) => {
            this.save_loader = false;
            let errors = err.response.data.errors;
     
            Object.keys(errors).forEach((e) => {
              errors[e].forEach((s) => {
                if (s.includes("already been taken")) {
                  this.$set(this, `${e}_exist`, true);
                  this.$refs.form.validate();
                } 
                if(s.includes("The photo must be a file of type: jpeg, jpg, png.")) {
                  this.photo_err = true;
                  this.$refs.form.validate();
                }
              });
            });
          });
      }
    },
    get_deliveries() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "delivery",
            method: "get",
          },
        })
        .then((res) => {
          this.deliveries = Object.assign([], res.data.data);
        });
    },
  },
  mounted() {
    // this.get_deliveries();
  },
};
</script>
